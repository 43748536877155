// @flow

import * as React from 'react';
import { graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import withWidth from '@material-ui/core/withWidth';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import compose from 'recompose/compose';
import type { HOC } from 'recompose';
import Image from 'gatsby-image';
import take from 'lodash/take';

import SectionWrapper from '../components/SectionWrapper';
import PageHelmet from '../plugins/helmet/components/PageHelmet';
import type { Query } from '../schema.flow';
import DinerImage from '../components/DinerImage';
import ContactSection from '../components/ContactSection';
import DinerInfoGrid from '../components/DinerInfoGrid';
import BlogSection from '../components/BlogSection';
import InstaGrid from '../components/InstaGrid';
import AboutUsGrid from '../components/AboutUsGrid';
import CareersSection from '../components/CareersSection';
import formatBlogEntryDate from '../helpers/formatBlogEntryDate';
import parseData from '../helpers/parseDate';

const styles = (theme: Theme) => ({
  root: {},
  wrapper: {},
  guttersV: {
    paddingTop: 0,
  },
  image: {
    width: '100%',
    maxHeight: '800px',
  },
  text: {
    textTransform: 'uppercase',
  },
  careersSection: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 90,
    },
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  page: string,
  width: Breakpoint,
  data: Query,
};

const RestaurantPage = ({ classes, data: query, width }: Props) => (
  <SectionWrapper>
    <PageHelmet data={query.prismicRestaurant?.data} />
    <SectionWrapper guttersV classes={{ guttersV: classes.guttersV }}>
      <DinerImage data={query.prismicRestaurant?.data} />

      <ContactSection
        page={query.prismicRestaurant?.id || ''}
        data={query.prismicMarquee?.data}
        restaurantTitle={
          query.prismicRestaurant?.data?.restaurant_title?.text || ''
        }
      />
    </SectionWrapper>
    <SectionWrapper guttersV justifyRight>
      <DinerInfoGrid data={query.prismicRestaurant?.data} />
    </SectionWrapper>
    <SectionWrapper guttersV justifyRight>
      {query.prismicRestaurant?.data?.body_image?.localFile?.childImageSharp ? (
        <Image
          {...query.prismicRestaurant?.data?.body_image?.localFile
            ?.childImageSharp}
          className={classes.image}
        />
      ) : null}
    </SectionWrapper>
    {/* {query.allPrismicBlogEntry && query.allPrismicBlogEntry.edges.length > 0 ? (
      <SectionWrapper limitWidth guttersH guttersV name="recipes">
        <BlogSection
          data={query.prismicBlogPage?.data}
          nodes={(() => {
            const nodes =
              (query.allPrismicBlogEntry &&
                query.allPrismicBlogEntry.edges.map(({ node }) => node)) ||
              [];

            const currentMonthNodes = nodes.filter(node =>
              node && node.first_publication_date
                ? formatBlogEntryDate({
                    date: parseData(node.first_publication_date),
                  }) === formatBlogEntryDate({ date: new Date() })
                : false,
            );

            return currentMonthNodes.length > 0
              ? currentMonthNodes
              : take(nodes, 3);
          })()}
        />
      </SectionWrapper>
    ) : null} */}

    <SectionWrapper limitWidth guttersH guttersV>
      <InstaGrid
        account={
          query.prismicRestaurant?.id ===
          'Prismic__Restaurant__XMhiQxAAAPFad6I_'
            ? '26grains'
            : 'StoneyStreet26'
        }
        numberOfMediaElements={9}
        // eslint-disable-next-line no-nested-ternary
        numberOfImages={width === 'xs' ? 3 : 6}
      />
    </SectionWrapper>
    {/* <SectionWrapper limitWidth guttersH guttersV name="about">
      <AboutUsGrid data={query.prismicAboutUs?.data} />
    </SectionWrapper> */}
    <SectionWrapper
      limitWidth
      guttersH
      guttersV
      name="careers"
      classes={{ root: classes.careersSection }}
    >
      <CareersSection
        title={query.prismicRestaurant?.data}
        data={query.prismicCareers?.data}
      />
    </SectionWrapper>
  </SectionWrapper>
);

type DiffProps = {
  classes: any,
  width: any,
};

const enhancer: HOC<Props, $Diff<Props, DiffProps>> = compose(
  withWidth(),
  withStyles(styles),
);
export default enhancer(RestaurantPage);

export const query = graphql`
  query PrismicRestaurant($prismicId: String) {
    prismicRestaurant(prismicId: { eq: $prismicId }) {
      id
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        restaurant_title {
          text
        }
        initial_image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1200, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        initial_image_sm {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 800, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body_image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1200, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        menu_title {
          text
        }
        menu_list {
          menus {
            id
            document {
              id
              data {
                title {
                  text
                }
                description {
                  text
                  html
                }
              }
            }
          }
        }
        menu_image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 500, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        schedule_title {
          text
        }
        schedule_content {
          text
          html
        }
        schedule_image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 500, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        location_title {
          text
        }
        location_content {
          text
          html
        }
        location_image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 500, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    prismicMarquee {
      id
      data {
        marquee_title {
          text
        }
        marquee_text {
          text
          html
        }
      }
    }
    prismicBlogPage {
      id
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        title {
          text
        }
      }
    }
    allPrismicBlogEntry(
      sort: { fields: [first_publication_date], order: DESC }
    ) {
      edges {
        node {
          id: prismicId
          first_publication_date
          data {
            title {
              text
            }
            image {
              localFile {
                id
                childImageSharp {
                  fluid(maxHeight: 500, quality: 82) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            body {
              ... on Node {
                id
              }
              ... on PrismicBlogEntryBodyText {
                prismicId
                type: slice_type
                primary {
                  text {
                    html
                  }
                }
              }
              ... on PrismicBlogEntryBodyImage {
                prismicId
                type: slice_type
                primary {
                  simpleImage: simple_image {
                    localFile {
                      id
                      childImageSharp {
                        fluid(maxWidth: 800, quality: 82) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicAboutUs {
      id
      lang
      data {
        about_title {
          text
        }
        about_description {
          text
          html
        }
        about_image {
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 600, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    prismicCareers {
      id
      data {
        title {
          text
        }
        description {
          text
          html
        }
        vacancies_list {
          vacancy {
            id
            document {
              id
              data {
                position {
                  text
                }
                description {
                  text
                  html
                }
                specifications {
                  url
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
