// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import withWidth from '@material-ui/core/withWidth';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import type { HOC } from 'recompose';
import compose from 'recompose/compose';
import Image from 'gatsby-image';
import Marquee from 'react-marquee';

import MailchimpForm from './MailchimpForm';
import Link from '../plugins/i18n/components/Link';
import type { Query, PrismicMarqueeData } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    position: 'fixed',
    width: 'calc(100% - 80px)',
    zIndex: 1300,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: -50,
      zIndex: 1351,
      padding: '0 20px 0 0',
    },
  },
  wrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  marquee: {
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  marqueeText: {
    textTransform: 'uppercase',
    wordSpacing: '1rem',
    ...theme.typography.h6,
  },
  socialContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      alignItems: 'unset',
    },
  },
  mail: {
    display: 'inherit',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  text: {
    paddingRight: theme.spacing.unit,
    fontWeight: '600',
  },
  succeess: {
    display: 'flex',
    alignItems: 'center',
  },
  errorContainer: {
    backgroundColor: theme.palette.primary.main,
    flexWrap: 'nowrap',
  },
  error: {
    marginTop: 0,
    '& a': {
      color: 'inherit',
    },
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'baseline',
  },
  iconBase: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    fontSize: 20,
    marginTop: 7,
    padding: '0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px',
    },
  },
  icon: {
    width: '25px',
  },
});

export type Props = {
  data: ?PrismicMarqueeData,
  page: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  restaurantTitle?: string,
};

const ContactSection = ({ classes, data, restaurantTitle }: Props) => {
  const [error, setError] = React.useState<?string>();
  const [errorCount, setErrorCount] = React.useState<number>(0);
  const [success, setSuccess] = React.useState<?string>();
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const onError = React.useCallback(
    ({ message }) => {
      console.log('erorro message', message);
      setError(message);
      setErrorCount(errorCount + 1);
    },
    [errorCount],
  );

  const onSuccess = React.useCallback(({ message }) => {
    console.log('message', message);
    setSnackbarOpen(true);
    setSuccess(message);
  });
  const onSnackbarClose = React.useCallback(() => setSnackbarOpen(false));
  React.useEffect(() => {
    if (error) {
      setSnackbarOpen(true);
    }
  }, [error, errorCount]);

  return (
    <StaticQuery
      query={graphql`
        query {
          icon: file(relativePath: { eq: "InstagramIcon.png" }) {
            childImageSharp {
              fluid(maxHeight: 25) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          icon2: file(relativePath: { eq: "CookieIcon.png" }) {
            childImageSharp {
              fluid(maxHeight: 25) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={(query: {
        icon: $ElementType<Query, 'file'>,
        icon2: $ElementType<Query, 'file'>,
      }) => (
        <>
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <div className={classes.marquee}>
                {data?.marquee_text ? (
                  <Marquee
                    className={classes.marqueeText}
                    text={data?.marquee_text?.text}
                    hoverToStop
                    duration={1000}
                    loop
                  />
                ) : null}
              </div>

              <div className={classes.socialContainer}>
                <div className={classes.mail}>
                  <Hidden only="xs">
                    <Typography variant="body1" className={classes.text}>
                      Join our mailing list:
                    </Typography>
                  </Hidden>
                  <MailchimpForm onError={onError} onSuccess={onSuccess} />
                  {restaurantTitle !== "Neal's Yard" && (
                    <Button
                      href="https://resy.com/cities/ldn/stoney-street"
                      target="_blank"
                      rel="noopener noreferrer"
                      disableRipple
                    >
                      <Typography variant="button" className={classes.link}>
                        BOOK NOW
                      </Typography>
                    </Button>
                  )}
                </div>

                <div className={classes.socialIcons}>
                  <IconButton
                    aria-label="Instagram"
                    href="https://www.instagram.com/26grains/"
                    target="_blank"
                    rel="noopener noreferrer"
                    disableRipple
                    classes={{ root: classes.iconBase }}
                  >
                    <Image
                      {...query?.icon?.childImageSharp}
                      className={classes.icon}
                    />
                  </IconButton>
                  <IconButton
                    aria-label="Cookie Icon"
                    component={Link}
                    to="/cookies"
                    disableRipple
                    classes={{ root: classes.iconBase }}
                  >
                    <Image
                      {...query?.icon2?.childImageSharp}
                      className={classes.icon}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <Snackbar
            open={snackbarOpen}
            onClose={onSnackbarClose}
            autoHideDuration={4000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            message={
              <FormHelperText
                dangerouslySetInnerHTML={{ __html: error || success }}
                className={classes.error}
              />
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                disableRipple
                onClick={onSnackbarClose}
                classes={{ root: classes.iconBase }}
              >
                <CloseIcon color="inherit" />
              </IconButton>,
            ]}
            ContentProps={{ classes: { root: classes.errorContainer } }}
          />
        </>
      )}
    />
  );
};

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { width: any, classes: any }>>,
    classes?: $Shape<$ElementType<Props, 'classes'>>,
  },
> = compose(
  withWidth(),
  withStyles(styles),
);

ContactSection.defaultProps = {
  restaurantTitle: undefined,
};

export default enhancer(ContactSection);

// export default withStyles(styles)(ContactSection);
