// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import reduce from 'lodash/reduce';

import HorizontalExpansionPanel, {
  type Item,
} from './HorizontalExpansionPanel';
import RichText from '../plugins/prismic/components/RichText';
import type { PrismicRestaurantData } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    width: '50vw',
    height: '700px',
    maxHeight: '85vh',
    right: '10%',
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
      height: '600px',
    },
  },
  container: {
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  data: ?PrismicRestaurantData,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const MenuSection = ({ classes, data }: Props) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <HorizontalExpansionPanel
        items={reduce(
          data?.menu_list || [],
          (items, listItem): Array<Item> => {
            const node = listItem?.menus?.document?.[0];
            const item: Item = {
              name: node?.id || '',
              title: node?.data?.title?.text || '',
              details: RichText.hasValue(node?.data?.description) ? (
                <Typography variant="body1" component="div">
                  <RichText {...node?.data?.description} />
                </Typography>
              ) : null,
            };

            return item.name && item.title && item.details
              ? [...items, item]
              : items;
          },
          [],
        )}
      />
    </div>
  </div>
);

export default withStyles(styles)(MenuSection);
