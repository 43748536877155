// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import type { HOC } from 'recompose';
import compose from 'recompose/compose';

import RestaurantTitle from './RestaurantTitle';
import NodeImage from '../plugins/prismic/components/NodeImage';
import type { PrismicRestaurantData } from '../schema.flow';

const styles = (theme: Theme) => ({
  imageSection: {
    width: '100%',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: 'calc(100vh - 65px)',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 55px)',
    },
  },
  textWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textTransform: 'uppercase',
  },
});

export type Props = {
  data: ?PrismicRestaurantData,
  width: Breakpoint,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const DinerImage = ({ classes, data, width }: Props) => (
  <div>
    <div className={classes.imageSection}>
      <NodeImage
        alt="Background image"
        data={
          isWidthDown('sm', width)
            ? data?.initial_image_sm
            : data?.initial_image
        }
        className={classes.image}
      />
    </div>
    {data?.restaurant_title?.text ? (
      <div className={classes.textWrapper}>
        <RestaurantTitle title={data?.restaurant_title?.text} />
      </div>
    ) : null}
  </div>
);

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { width: any, classes: any }>>,
    classes?: $Shape<$ElementType<Props, 'classes'>>,
  },
> = compose(
  withWidth(),
  withStyles(styles),
);
export default enhancer(DinerImage);
