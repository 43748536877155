// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import type { HOC } from 'recompose';
import compose from 'recompose/compose';
import classnames from 'classnames';
import map from 'lodash/map';
import { AlphabetSoupWithDimensions } from 'react-alphabet-soup';

import CareersSectionItem from './CareersSectionItem';
import RestaurantTitle from '../RestaurantTitle';
import RichText from '../../plugins/prismic/components/RichText';
import type {
  PrismicCareersData,
  PrismicRestaurantData,
} from '../../schema.flow';

const styles = (theme: Theme) => ({
  root: {},
  wrapper: {
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
    },
  },
  titleContainer: {
    width: '150px',
    height: '80vh',
    paddingRight: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      width: '70px',
      height: '280px',
    },
  },
  titleBeforeHover: {
    fontSize: `70px !important`,
    [theme.breakpoints.down('xs')]: {
      fontSize: `40px !important`,
    },
  },
  titleCharacters: {
    fontFamily: theme.typography.h1.fontFamily,
    opacity: 0.7,
  },
  restaurantTitle: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing.unit * 4,
    },
  },
  vacanciesSection: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      maxHeight: '80vh',
      overflow: 'auto',
    },
  },
  sectionTitle: {
    textTransform: 'uppercase',
    '&::after': {
      content: JSON.stringify('---'),
      display: 'block',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  vacanciesList: {
    paddingTop: theme.spacing.unit * 6,
  },
});

export type Props = {
  title: ?PrismicRestaurantData,
  data: ?PrismicCareersData,
  width: Breakpoint,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const CareersSection = ({ classes, data, title, width }: Props) => (
  <div className={classes.root}>
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.titleContainer}>
        <AlphabetSoupWithDimensions
          text="CAREERS"
          fontSize={isWidthDown('xs', width) ? '35px' : '60px'}
          fontFamily="Hurme Geometric Sans"
          sorting="sortByY"
          transitionSpeedMultiplier={0.6}
          transitionStyle="constant"
          wrapperClassName={classes.titleBeforeHover}
          charactersClassName={classes.titleCharacters}
          untidyOnHover
          vertical
        />
      </Grid>
      <Grid
        item
        xs
        container
        spacing={32}
        alignItems="center"
        justify="space-around"
        style={{ width: 0 }}
      >
        <Grid item xs={12} md={4} className={classes.restaurantTitle}>
          <RestaurantTitle title={title?.restaurant_title?.text} />
        </Grid>
        <Grid item xs={12} md={5} className={classes.vacanciesSection}>
          {data?.title?.text ? (
            <Typography
              variant="body1"
              className={classnames(classes.boldText, classes.sectionTitle)}
            >
              {data?.title?.text}
            </Typography>
          ) : null}
          {RichText.hasValue(data?.description) ? (
            <Typography
              component="div"
              variant="body1"
              className={classes.boldText}
            >
              <RichText {...data?.description} />
            </Typography>
          ) : null}
          {data && data?.vacancies_list ? (
            <div className={classes.vacanciesList}>
              {map(data?.vacancies_list, listItem => (
                <CareersSectionItem
                  key={listItem?.vacancy?.id}
                  data={listItem?.vacancy?.document?.[0]?.data}
                />
              ))}
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { width: any, classes: any }>>,
    classes?: $Shape<$ElementType<Props, 'classes'>>,
  },
> = compose(
  withWidth(),
  withStyles(styles),
);
export default enhancer(CareersSection);
