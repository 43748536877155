// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import Typography from '@material-ui/core/Typography';

import type { Query } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      zoom: 1.3,
    },
  },
  text: {
    textTransform: 'uppercase',
  },
});

export type Props = {
  title?: React.Node,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const DinerImage = ({ classes, title }: Props) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "26GLogo.png" }) {
          childImageSharp {
            fixed(width: 180) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(query: { logo: $ElementType<Query, 'file'> }) => {
      return (
        <div className={classes.root}>
          <Image {...query?.logo?.childImageSharp} />
          {title ? (
            <>
              <Typography variant="h6" align="center" className={classes.text}>
                Of
              </Typography>
              <Typography variant="h6" align="center" className={classes.text}>
                {title}
              </Typography>
            </>
          ) : null}
        </div>
      );
    }}
  />
);

DinerImage.defaultProps = {
  title: undefined,
};

export default withStyles(styles)(DinerImage);
