// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Parallax } from 'react-scroll-parallax';
import type { HOC } from 'recompose';
import compose from 'recompose/compose';
import Image from 'gatsby-image';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AlphabetSoupWithDimensions } from 'react-alphabet-soup';

import RichText from '../plugins/prismic/components/RichText';
import type { PrismicAboutUsData } from '../schema.flow';

const styles = (theme: Theme) => ({
  wrapper: {
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
    },
  },
  titleContainer: {
    width: '150px',
    height: '80vh',
    paddingRight: theme.spacing.unit * 5,
    [theme.breakpoints.down('xs')]: {
      width: '70px',
      height: '380px',
    },
  },
  titleBeforeHover: {
    fontSize: `70px !important`,
    [theme.breakpoints.down('xs')]: {
      fontSize: `40px !important`,
    },
  },
  titleCharacters: {
    fontFamily: theme.typography.h1.fontFamily,
    opacity: 0.7,
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  data: ?PrismicAboutUsData,
  width: Breakpoint,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const AboutUsGrid = ({ classes, data, width }: Props) =>
  data ? (
    <div>
      <Grid container className={classes.wrapper}>
        <Grid item className={classes.titleContainer}>
          <AlphabetSoupWithDimensions
            text="ABOUT US"
            fontSize={isWidthDown('xs', width) ? '35px' : '60px'}
            fontFamily="Hurme Geometric Sans"
            sorting="sortByY"
            transitionSpeedMultiplier={0.6}
            transitionStyle="constant"
            wrapperClassName={classes.titleBeforeHover}
            charactersClassName={classes.titleCharacters}
            untidyOnHover
            vertical
          />
        </Grid>
        <Grid item xs container spacing={32}>
          <Grid item xs={12} md={8} className={classes.imageContainer}>
            <Parallax y={[-15, 15]}>
              {data?.about_image?.localFile?.childImageSharp ? (
                <Image
                  {...data?.about_image?.localFile?.childImageSharp}
                  className={classes.image}
                />
              ) : null}
            </Parallax>
          </Grid>
          <Grid item xs={12} md={4}>
            {RichText.hasValue(data?.about_description) ? (
              <Typography variant="body1" component="div">
                <RichText {...data?.about_description} />
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  ) : null;

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { width: any, classes: any }>>,
    classes?: $Shape<$ElementType<Props, 'classes'>>,
  },
> = compose(
  withWidth(),
  withStyles(styles),
);
export default enhancer(AboutUsGrid);
