// @flow
import type { PrismicRestaurantData } from '../schema.flow';
import type { RestaurantSection, RestaurantSections } from '../types';

const getRestaurantSection = (
  data: PrismicRestaurantData,
  section: RestaurantSections,
): RestaurantSection => ({
  section,
  title: data[`${section}_title`],
  content: data[`${section}_content`],
  image: data[`${section}_image`],
  external_link: data[`${section}_external_link`],
});

export default getRestaurantSection;
