// @flow

import * as React from 'react';
import withInstagramFeed from 'origen-react-instagram-feed';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import compose from 'recompose/compose';
import take from 'lodash/take';
import {
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const styles = () => ({
  wrapper: {
    textAlign: 'center',
  },
  button: {
    width: '25vw',
    height: '25vw',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  media?: Array<{
    displayImage: string,
    thumbnail: string,
    id?: string,
    postLink?: string,
    accessibilityCaption?: string,
  }>,
  numberOfImages?: number,
  account: string,
  scrollPosition: {| x: number, y: number |},
  classes: { [$Keys<$Call<typeof styles>>]: string },
  status: 'completed' | 'loading' | 'failed',
};

const InstaGrid = ({
  classes,
  media,
  account,
  status,
  scrollPosition,
  numberOfImages,
}: Props) => {
  return (
    <Grid container spacing={40} className={classes.wrapper}>
      {media &&
        status === 'completed' &&
        (Number.isFinite(numberOfImages)
          ? take(media, numberOfImages)
          : media
        ).map(({ thumbnail, id, postLink, accessibilityCaption }) => (
          <Grid item xs={6} sm={6} md={4} key={id || thumbnail}>
            <ButtonBase
              aria-label="Instagram"
              href={postLink || `https://www.instagram.com/${account}/`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.button}
              disableRipple
            >
              <LazyLoadImage
                src={thumbnail}
                alt={accessibilityCaption || 'Instagram picture'}
                className={classes.image}
                effect="blur"
                scrollPosition={scrollPosition}
              />
            </ButtonBase>
          </Grid>
        ))}
      {status === 'loading' && <p>loading...</p>}
      {status === 'failed' && <p>Check instagram here</p>}
    </Grid>
  );
};

InstaGrid.defaultProps = {
  media: undefined,
  numberOfImages: undefined,
};

export default compose(
  withInstagramFeed,
  trackWindowScroll,
  withStyles(styles),
)(InstaGrid);
