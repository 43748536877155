// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Image from 'gatsby-image';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { Parallax } from 'react-scroll-parallax';
import classnames from 'classnames';

import DinerInfoPopup from './DinerInfoPopup';
import type { RestaurantSection } from '../../types';

const styles = () => ({
  button: {},
  title: {},
  text: {
    textTransform: 'uppercase',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
  },
});

export type Props = {
  className?: string,
  children?: React.Node,
  classes: { [$Keys<$Call<typeof styles>>]: string },
  ...$Exact<RestaurantSection>,
};

export type State = {
  open: boolean,
  anchorEl: ?HTMLElement,
};

class DinerInfoItem extends React.Component<Props, State> {
  static defaultProps = {
    className: undefined,
    children: undefined,
  };

  state = {
    open: false,
    anchorEl: null,
  };

  handleClick = (event: Event) => {
    this.setState({
      open: true,
      // $FlowFixMe --> Reason: cannot cast EventTarget to HTMLElement
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      title,
      content,
      image,
      external_link,
      className,
      children,
      ...props
    } = this.props;
    const { open, anchorEl } = this.state;

    return (
      <>
        <ButtonBase
          type="button"
          aria-label="Restaurant info"
          href={external_link?.url}
          target="_blank"
          disableRipple
          {...props}
          className={classnames(classes.button, className)}
          onClick={!external_link?.url && this.handleClick}
        >
          <div className={classes.title}>
            {title ? (
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.text}
              >
                {title.text}
              </Typography>
            ) : null}
          </div>
          <div className={classes.imageContainer}>
            <Parallax y={[-15, 15]}>
              {image?.localFile?.childImageSharp ? (
                <Image
                  {...image?.localFile?.childImageSharp}
                  className={classes.image}
                />
              ) : null}
            </Parallax>
          </div>
        </ButtonBase>
        <DinerInfoPopup
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          content={content}
        >
          {children}
        </DinerInfoPopup>
      </>
    );
  }
}

export default withStyles(styles)(DinerInfoItem);
