// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import RichText from '../../plugins/prismic/components/RichText';
import type { RestaurantSection } from '../../types';

const styles = (theme: Theme) => ({
  root: {
    zIndex: 2100,
    float: 'right',
  },
  paper: {
    borderRadius: 0,
    border: '1px solid black',
    minWidth: '300px', // to fix! it has to be the buttonBase width
  },
  contentContainer: {
    padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 2,
  },
  colorDefault: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.contrastText,
  },
});

export type Props = {
  open: boolean,
  onClose: Event => void,
  anchorEl: ?HTMLElement,
  content: $ElementType<RestaurantSection, 'content'>,
  children?: React.Node,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const DinerInfoPopup = ({
  classes,
  open,
  onClose,
  anchorEl,
  content,
  children,
}: Props) => (
  <Popover
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
    ModalClasses={{ root: classes.root }}
    classes={{ paper: classes.paper }}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
  >
    <div className={classes.closeButton}>
      <ButtonBase onClick={onClose} aria-label="Close" disableTouchRipple>
        <Avatar classes={{ colorDefault: classes.colorDefault }}>
          <Typography variant="body1">X</Typography>
        </Avatar>
      </ButtonBase>
    </div>
    {RichText.hasValue(content) ? (
      <div className={classes.contentContainer}>
        <Typography variant="body1" component="div">
          <RichText {...content} />
        </Typography>
      </div>
    ) : (
      children || null
    )}
  </Popover>
);

DinerInfoPopup.defaultProps = {
  children: undefined,
};

export default withStyles(styles)(DinerInfoPopup);
