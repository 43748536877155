// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import type { HOC } from 'recompose';
import compose from 'recompose/compose';
import classnames from 'classnames';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    fontFamily: theme.typography.body1.fontFamily,
  },
  iconBase: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover $icon': {
      color: theme.palette.common.black,
    },
  },
  icon: {},
});

const defaultValues = { email: '' };

export type Values = {
  email: string,
};

export type Props = {
  width: Breakpoint,
  className?: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  initialValues?: Values,
  onSuccess?: ({ message: string, values: Values }) => void,
  onError?: ({ message: string, values: Values }) => void,
};

const MailchimpForm = ({
  width,
  classes,
  className,
  initialValues = defaultValues,
  onSuccess,
  onError,
}: Props) => {
  const [values, setValues] = React.useState<Values>(initialValues);

  const handleChange = React.useCallback(
    (event: SyntheticInputEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.target;
      setValues(prevValues => ({ ...prevValues, [`${name}`]: value }));
    },
  );

  const handleSubmit = React.useCallback(
    (event: Event) => {
      event.preventDefault();
      const { email, ...rest } = values;
      addToMailchimp(email, rest)
        .then(({ msg: message, result }) => {
          if (result === 'success' && onSuccess) {
            onSuccess({ message, values });
          } else if (onError) {
            onError({ message, values });
          }
        })
        .catch(() => {
          // do nothing, as addToMailchimp will never throw
        });
    },
    [values],
  );

  return (
    <form
      onSubmit={handleSubmit}
      className={classnames(className, classes.root)}
    >
      <InputBase
        type="email"
        name="email"
        classes={{ input: classes.input }}
        inputProps={{ label: 'Email input', 'aria-label': 'Email input' }}
        placeholder={
          isWidthDown('xs', width)
            ? 'Subscribe our mailing list'
            : 'Enter email adress'
        }
        onChange={handleChange}
      />
      <IconButton
        aria-label="Submit icon"
        type="submit"
        disableRipple
        classes={{ root: classes.iconBase }}
      >
        <ArrowIcon className={classes.icon} />
      </IconButton>
    </form>
  );
};

MailchimpForm.defaultProps = {
  className: undefined,
  initialValues: defaultValues,
  onSuccess: undefined,
  onError: undefined,
};

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { width: any, classes: any }>>,
    classes?: $Shape<$ElementType<Props, 'classes'>>,
  },
> = compose(
  withWidth(),
  withStyles(styles),
);

export default enhancer(MailchimpForm);
