// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import Image from 'gatsby-image';
import type { HOC } from 'recompose';
import compose from 'recompose/compose';
import classnames from 'classnames';

import Link from '../../plugins/i18n/components/Link';
import type { PrismicBlogEntry } from '../../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    '&:hover $actionButton': {
      opacity: 1,
      transform: 'translateY(-20px)',
    },
  },
  textContainer: {
    height: '100%',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  text: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6rem',
      textAlign: 'center',
    },
  },
  actionButton: {
    opacity: 0,
    marginTop: '40px',
    transitionProperty: 'all',
    transitionDuration: `${theme.transitions.duration.standard * 2}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    marginLeft: theme.spacing.unit * 2,
    color: theme.palette.common.black,
  },
  imageContainer: {},
  image: {
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  node: ?PrismicBlogEntry,
  className?: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const BlogEntry = ({ className, classes, node }: Props) =>
  node ? (
    <ButtonBase
      component={Link}
      to={`newsletter/${node.id}`}
      aria-label="Blog entry"
      disableRipple
      className={classnames(classes.root, className)}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} sm={4} className={classes.textContainer}>
          <Typography variant="h2" align="left" className={classes.text}>
            {node?.data?.title?.text}
          </Typography>
          <div className={classes.actionButton}>
            <Typography variant="h6" align="left">
              READ MORE
            </Typography>
            <ArrowIcon className={classes.icon} />
          </div>
        </Grid>
        <Hidden only="xs">
          <Grid item sm={8} className={classes.imageContainer}>
            {node?.data?.image?.localFile?.childImageSharp ? (
              <Image
                {...node?.data?.image?.localFile?.childImageSharp}
                className={classes.image}
              />
            ) : null}
          </Grid>
        </Hidden>
      </Grid>
    </ButtonBase>
  ) : null;

BlogEntry.defaultProps = {
  className: undefined,
};

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { classes: any }>>,
    classes?: $Shape<$ElementType<Props, 'classes'>>,
  },
> = compose(
  withWidth(),
  withStyles(styles),
);
export default enhancer(BlogEntry);
