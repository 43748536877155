// @flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import compose from 'recompose/compose';
import withStateHandlers from 'recompose/withStateHandlers';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AlphabetSoupWithDimensions } from 'react-alphabet-soup';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import type { HOC } from 'recompose';

const ITEM_WIDTH_MD = '120px';
const ITEM_WIDTH_SM = '90px';
const ITEM_WIDTH_XS = '75px';
const WIDTH_TRANSITION_TIME = '0.5s';
const TRANSFORM_TRANSITION_TIME = WIDTH_TRANSITION_TIME;
const OPACITY_TRANSITION_TIME = '0.3s';
const OPACITY_DELAY = '0.14s';

const styles = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',

    overflow: 'hidden',
  },
  item: {
    width: ITEM_WIDTH_MD,
    height: '100%',
    transition: `width ${WIDTH_TRANSITION_TIME} ease, transform ${TRANSFORM_TRANSITION_TIME} ease, opacity ${OPACITY_TRANSITION_TIME} cubic-bezier(0.6, 0, 0.39, 0.98) ${OPACITY_DELAY}`,
    display: 'flex',
    opacity: 1,
    [theme.breakpoints.down('md')]: {
      width: ITEM_WIDTH_SM,
    },
    [theme.breakpoints.down('sm')]: {
      width: ITEM_WIDTH_XS,
    },
  },
  summary: {
    width: ITEM_WIDTH_MD,
    height: '100%',
    // borderRight: '3px solid black',
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.down('md')]: {
      width: ITEM_WIDTH_SM,
    },
    [theme.breakpoints.down('sm')]: {
      width: ITEM_WIDTH_XS,
    },
  },
  titleBeforeHover: {
    fontSize: `45px !important`,
    [theme.breakpoints.down('xs')]: {
      fontSize: `35px !important`,
    },
  },
  titleCharacters: {
    fontFamily: theme.typography.h1.fontFamily,
    textTransform: 'uppercase',
  },
  details: {
    position: 'absolute',
    width: `calc(100% - ${ITEM_WIDTH_MD})`,
    height: '100%',
    overflow: 'hidden',
    opacity: 0,
    zIndex: -1,
    transition: `transform ${TRANSFORM_TRANSITION_TIME} ease, opacity ${OPACITY_TRANSITION_TIME} cubic-bezier(0.6, 0, 0.39, 0.98)`,
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${ITEM_WIDTH_SM})`,
    },
    // Scrollbar style for Webkit e.g. Chrome, Safari, Opera...
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '12px',
      webkitBoxShadow: 'none',
      backgroundColor: '#A2A2A2',
    },
    // Firefox
    scrollbarWidth: 'thin',
    scrollbarColor: '#A2A2A2 transparent',
  },
  itemsExpanded: {
    width: '100%',
    maxHeight: '100%',
  },
  itemsHidden: {
    width: '0px',
    overflow: 'hidden',
    opacity: 0,
  },
  detailsExpanded: {
    opacity: 1,
    zIndex: 0,
    overflow: 'auto',
    animationDuration: TRANSFORM_TRANSITION_TIME,
    animationName: `HorizontalExpansionPanel-delay-overflow`,
    transition: `transform ${TRANSFORM_TRANSITION_TIME} ease, opacity ${OPACITY_TRANSITION_TIME} cubic-bezier(0.6, 0, 0.39, 0.98) ${OPACITY_DELAY}`,
    transform: `translate3D(${ITEM_WIDTH_MD}, 0, 0)`,
    [theme.breakpoints.down('md')]: {
      transform: `translate3D(${ITEM_WIDTH_SM}, 0, 0)`,
    },
    [theme.breakpoints.down('sm')]: {
      transform: `translate3D(${ITEM_WIDTH_SM}, 0, 0)`,
    },
  },
  '@keyframes HorizontalExpansionPanel-delay-overflow': {
    from: { overflow: 'hidden' },
    to: { overflow: 'hidden' },
  },
  content: {
    padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 3}px`,
    height: 'auto',
    overflow: 'auto',
  },
  contentExpanded: {
    height: 'auto',
  },
  backButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 1,
  },
  colorDefault: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.contrastText,
  },
});

export type Item = {|
  name: string,
  title: string,
  details: React.Node,
|};

type Props = {
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
  expandedPanel?: string,
  setExpandedPanel: (value: string) => void,
  closeExpanded: () => void,
  closeLabel?: $ElementType<React.ElementConfig<typeof Avatar>, 'children'>,
  width: Breakpoint,
  items: Array<Item>,
};

const HorizontalExpansionPanel = ({
  classes,
  expandedPanel,
  setExpandedPanel,
  closeExpanded,
  closeLabel,
  width,
  items,
}: Props) => {
  return (
    <div className={classes.wrapper}>
      {items.map(({ name, title, details }) => (
        <div
          key={name}
          className={classNames(classes.item, {
            [classes.itemsExpanded]: expandedPanel === name,
            [classes.itemsHidden]: expandedPanel && expandedPanel !== name,
          })}
        >
          <ButtonBase
            aria-label="Expanded panel button"
            focusRipple={false}
            disableRipple
            component="div"
            onClick={
              expandedPanel !== name
                ? () => setExpandedPanel(name)
                : closeExpanded
            }
            className={classes.summary}
          >
            <AlphabetSoupWithDimensions
              text={title}
              fontSize={isWidthDown('xs', width) ? '35px' : '45px'}
              fontFamily="Hurme Geometric Sans"
              sorting="sortByY"
              transitionSpeedMultiplier={0.6}
              transitionStyle="constant"
              maxIterations={7} // limit number of iteration if the browser gets some errors
              wrapperClassName={classes.titleBeforeHover}
              charactersClassName={classes.titleCharacters}
              vertical
              untidyOnHover
            />
          </ButtonBase>
          <div
            className={classNames(classes.details, {
              [classes.detailsExpanded]: expandedPanel === name,
            })}
          >
            <div
              className={classNames(classes.content, {
                [classes.contentExpanded]: expandedPanel === name,
              })}
            >
              {details}
            </div>
            {closeLabel ? (
              <div className={classes.backButton}>
                <ButtonBase
                  aria-label="Close"
                  onClick={closeExpanded}
                  disableTouchRipple
                >
                  <Avatar classes={{ colorDefault: classes.colorDefault }}>
                    {closeLabel}
                  </Avatar>
                </ButtonBase>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

HorizontalExpansionPanel.defaultProps = {
  expandedPanel: undefined,
  closeLabel: <ArrowLeftIcon />,
};

const enhancer: HOC<
  Props,
  {
    ...$Exact<
      $Diff<
        Props,
        {
          classes: any,
          expandedPanel: any,
          setExpandedPanel: any,
          closeExpanded: any,
          width: any,
        },
      >,
    >,
    classes?: $Shape<$ElementType<Props, 'classes'>>,
  },
> = compose(
  withStateHandlers(
    { expandedPanel: undefined },
    {
      setExpandedPanel: () => expandedPanel => ({ expandedPanel }),
      closeExpanded: () => () => ({ expandedPanel: undefined }),
    },
  ),
  withWidth(),
  withStyles(styles),
);

export default enhancer(HorizontalExpansionPanel);
