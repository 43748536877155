// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import RichText from '../../plugins/prismic/components/RichText';
import type { PrismicJobVacancyData } from '../../schema.flow';

const styles = () => ({
  root: {
    width: '100%',
    paddingBottom: '40px',
  },
  capitalText: {
    textTransform: 'uppercase',
  },
  positionTitle: {
    fontWeight: 'bold',
    '&::after': {
      content: JSON.stringify('...'),
      display: 'block',
    },
  },
  downloadButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    textDecoration: 'underline',
  },
});

export type Props = {
  data: ?PrismicJobVacancyData,
  classes: { [$Keys<$Call<typeof styles>>]: string },
};

const CareersSectionItem = ({ classes, data }: Props) =>
  data ? (
    <div className={classes.root}>
      {data?.position?.text ? (
        <Typography
          variant="body1"
          className={classnames(classes.capitalText, classes.positionTitle)}
        >
          {data?.position?.text}
        </Typography>
      ) : null}
      {RichText.hasValue(data?.description) ? (
        <Typography component="div" variant="body1">
          <RichText {...data?.description} />
        </Typography>
      ) : null}
      {data?.specifications?.url ? (
        <Button
          aria-label="Download"
          href={data?.specifications?.url || ''}
          download={data?.specifications?.name}
          className={classes.downloadButton}
          target="_blank"
          rel="noopener noreferrer"
          disableRipple
        >
          <Typography variant="button" className={classes.link}>
            Download job specification
          </Typography>
        </Button>
      ) : null}
    </div>
  ) : null;

export default withStyles(styles)(CareersSectionItem);
