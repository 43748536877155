// @flow

import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles, { type Theme } from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import DinerInfoItem from './DinerInfoItem';
import MenuSection from '../MenuSection';
import type { PrismicRestaurantData } from '../../schema.flow';
import { MENU, SCHEDULE, LOCATION } from '../../constants/restaurantSections';
import type { RestaurantSections } from '../../constants/types';
import getRestaurantSection from '../../helpers/getRestaurantSection';

const styles = (theme: Theme) => ({
  button: {
    width: '80%',
    height: 'auto',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '320px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '200px',
    },
    transitionProperty: 'opacity',
    transitionDuration: `${theme.transitions.duration.standard * 1}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
  innactiveSection: {
    opacity: 0.5,
  },
  title: {
    position: 'absolute',
    zIndex: '1',
  },
  [SCHEDULE]: {
    order: 1,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
    '& $button': {
      marginLeft: '5%',
    },
    '& $title': {
      top: '50%',
      right: '-9%',
      [theme.breakpoints.down('md')]: {
        top: '450px',
        left: '300px',
      },
      [theme.breakpoints.down('sm')]: {
        top: '330px',
        left: '240px',
      },
      [theme.breakpoints.down('xs')]: {
        top: '220px',
        left: '120px',
      },
    },
  },
  [MENU]: {
    order: 2,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      order: 1,
      paddingBottom: theme.spacing.unit * 10,
    },
    '& $button': {
      float: 'right',
      marginRight: '5%',
      marginTop: '25%',
    },
    '& $title': {
      top: '-40px',
      left: '20px',
      [theme.breakpoints.down('sm')]: {
        top: '-15px',
      },
    },
  },
  [LOCATION]: {
    order: 3,
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing.unit * 10,
    },
    '& $button': {
      [theme.breakpoints.down('xs')]: {
        float: 'right',
      },
    },
    '& $title': {
      top: '40%',
      right: '-30%',
      [theme.breakpoints.down('lg')]: {
        top: '250px',
      },
      [theme.breakpoints.down('sm')]: {
        top: '188px',
      },
      [theme.breakpoints.down('xs')]: {
        top: '135px',
        left: '-110px',
        right: 'auto',
      },
      // don't know how to fix this specific case
      '@media (max-width:360px)': {
        left: '-55px',
      },
    },
  },
});

const renderSectionChildren = ({
  // eslint-disable-next-line react/display-name
  [MENU]: data => {
    return <MenuSection data={data} />;
  },
}: { [RestaurantSections]: (PrismicRestaurantData) => React.Node });

export type Props = {
  sections?: Array<RestaurantSections>,
  data: ?PrismicRestaurantData,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const DinerInfoGrid = ({ sections, data, classes }: Props) => {
  const [hoverSection, setHoverSection] = React.useState<?RestaurantSections>();
  const curryOnFocus = (section: RestaurantSections) => () =>
    setHoverSection(section);
  const onBlur = React.useCallback(() => setHoverSection(null));

  return data ? (
    <Grid container justify="center">
      {sections &&
        sections.map(section => (
          <Grid
            item
            id={section}
            key={section}
            xs={12}
            sm={6}
            className={classes[section]}
          >
            <DinerInfoItem
              {...getRestaurantSection(data, section)}
              classes={{
                button: classnames(classes.button, {
                  [classes.innactiveSection]:
                    hoverSection && hoverSection !== section,
                }),
                title: classes.title,
              }}
              onFocus={curryOnFocus(section)}
              onBlur={onBlur}
              onMouseOver={curryOnFocus(section)}
              onMouseOut={onBlur}
            >
              {renderSectionChildren[section] &&
                renderSectionChildren[section](data)}
            </DinerInfoItem>
          </Grid>
        ))}
    </Grid>
  ) : null;
};

DinerInfoGrid.defaultProps = {
  sections: [SCHEDULE, MENU, LOCATION],
};

export default withStyles(styles)(DinerInfoGrid);
