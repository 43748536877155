// @flow

import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Carousel from 'nuka-carousel';
import classnames from 'classnames';

import BlogEntry from './BlogEntry';
import SectionWrapper from './SectionWrapper';
import Link from '../plugins/i18n/components/Link';
import formatBlogEntryDate from '../helpers/formatBlogEntryDate';
import parseDate from '../helpers/parseDate';

import type { PrismicBlogEntry, PrismicBlogPageData } from '../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    '&:hover $opacityTransition': {
      opacity: 1,
    },
  },
  iconBase: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover $icon': {
      color: theme.palette.common.black,
    },
  },
  icon: {},
  carousel: {
    '& .slider-list:not([style*="height: 0px"]) .slider-slide': {
      minHeight: '500px',
      maxHeight: '100%',
      display: 'flex !important',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        minHeight: '250px',
        maxHeight: '100%',
      },
    },
  },
  blogEntry: {},
  opacityTransition: {
    opacity: 0.7,
    transitionProperty: 'all',
    transitionDuration: `${theme.transitions.duration.standard * 2}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    [theme.breakpoints.down('sm')]: {
      opacity: 1,
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  text: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.1rem',
    },
  },
  guttersV: {
    paddingTop: theme.spacing.unit * 7,
    paddingBottom: theme.spacing.unit * 7,
  },
  actions: {
    width: 'fit-content',
  },
  link: {
    textTransform: 'uppercase',
    textDecoration: 'underline',
  },
});

export type Props = {
  data: ?PrismicBlogPageData,
  nodes: ?Array<PrismicBlogEntry>,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const BlogSection = ({ classes, data, nodes }: Props): React.Node => {
  const renderCenterLeftControls = React.useCallback(({ previousSlide }) => (
    <IconButton
      aria-label="Left arrow"
      onClick={previousSlide}
      disableRipple
      className={classes.iconBase}
    >
      <ArrowLeftIcon fontSize="large" className={classes.icon} />
    </IconButton>
  ));

  const renderCenterRightControls = React.useCallback(({ nextSlide }) => (
    <IconButton
      aria-label="Right arrow"
      onClick={nextSlide}
      disableRipple
      className={classes.iconBase}
    >
      <ArrowRightIcon fontSize="large" className={classes.icon} />
    </IconButton>
  ));

  const [slideIndex, setSlideIndex] = React.useState<number>(0);

  const publicationDateString: ?string =
    nodes?.[slideIndex]?.first_publication_date;

  const date = publicationDateString ? parseDate(publicationDateString) : null;
  const fullDate = date ? formatBlogEntryDate({ date }) : null;

  return data ? (
    <div id="newsletter" className={classes.root}>
      <div
        className={classnames(classes.titleWrapper, classes.opacityTransition)}
      >
        {data?.title?.text ? (
          <Typography variant="h1" className={classes.text}>
            {data?.title?.text}
          </Typography>
        ) : null}
        {fullDate ? (
          <Typography variant="h1" className={classes.text}>
            {fullDate}
          </Typography>
        ) : null}
      </div>
      <SectionWrapper guttersV classes={{ guttersV: classes.guttersV }}>
        {nodes && nodes.length > 0 ? (
          <Carousel
            afterSlide={setSlideIndex}
            slideWidth={0.8}
            cellAlign="center"
            cellSpacing={200}
            heightMode="first"
            enableKeyboardControls
            renderCenterLeftControls={renderCenterLeftControls}
            renderCenterRightControls={renderCenterRightControls}
            renderBottomCenterControls={null}
            dragging={nodes.length > 1}
            swiping={nodes.length > 1}
            withoutControls={nodes.length <= 1}
            className={classnames(classes.carousel, classes.opacityTransition)}
          >
            {nodes.map(node => (
              <BlogEntry
                key={node.id}
                node={node}
                className={classes.blogEntry}
              />
            ))}
          </Carousel>
        ) : null}
      </SectionWrapper>
      <div className={classes.actions}>
        <Typography
          component={Link}
          to="/archive"
          variant="button"
          className={classes.link}
        >
          Read more posts
        </Typography>
      </div>
    </div>
  ) : null;
};

export default withStyles(styles)(BlogSection);
